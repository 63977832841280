import {
  BookOpen,
} from "react-feather";
import SettingsIcon from '@mui/icons-material/Settings';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FactoryIcon from '@mui/icons-material/Factory';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import HomeIcon from '@mui/icons-material/Home';

const pagesSection = [
  {
    href: "/",
    icon: HomeIcon,
    title: "Dashboard",
  },
  {
    href: "/pages",
    icon: BookOpen,
    title: "Gestion",
    children: [
      {
        href: "/gestion/devis",
        title: "Devis",
        permission: "devis_view"
      },
      {
        href: "/gestion/commande",
        title: "Commande",
        permission: "commande_view"
      },
      {
        title: "Bon de Livraison",
        children: [
          {
            href: "/gestion/bon",
            permission: "bon_view",
            title: "Bon Simple",
          },
          {
            href: "/gestion/bon/multiple",
            permission: "bon_view",
            title: "Bon Multiple",
          },
        ]
      },
      {
        href: "/gestion/facture",
        title: "Facture",
        permission: "facture_view"
      },
      {
        href: "/gestion/facture/auto",
        title: "Facture Auto",
        permission: "facture_view"
      },
      {
        href: "/gestion/achat",
        title: "Achat",
        permission: "achat_view"
      },
            {
        href: "/gestion/container",
        title: "Container",
        permission: "reception_view"
      },
      {
        href: "/gestion/reception",
        title: "Reception",
        permission: "reception_view"
      },
    ],
  },
  {
    href: "/file",
    icon: FactoryIcon,
    title: "Fichier de Base",
    children: [
      {
        href: "/file/client",
        title: "Clients",
        permission: "client_view"
      },
      {
        href: "/file/matiere",
        title: "Matières",
        permission: "matiere_view"
      },
      {
        href: "/file/article",
        title: "Articles",
        permission: "article_view"
      },
      {
        href: "/file/fournisseur",
        title: "Fournisseurs",
        permission: "fournisseur_view"
      },
      {
        href: "/file/stock",
        title: "Stocks",
        permission: "stock_view"
      },
      {
        href: "/file/carrousel",
        title: "Carrousel",
        permission: "stock_view"
      },
    ],
  },
  {
    href: "/settings",
    icon: SettingsIcon,
    title: "Paramètres",
    children: [ 
      {
        href: "/settings/setting",
        title: "Paramètres",
        permission: "settings_view"
      },
      {
        href: "/settings/users",
        title: "Utilisateurs",
        permission: "settings_view"
      },
      {
        href: "/settings/users",
        title: "Gestion des Droits",
        permission: "settings_view"
      },
      {
        href: "/settings/modereglement",
        title: "Modes de Règlement",
        permission: "settings_view"
      },
      {
        href: "/settings/livraisonTerm",
        title: "Terme de Livraison",
        permission: "settings_view"
      },
      {
        href: "/settings/paiementTerm",
        title: "Terme de Paiement",
        permission: "settings_view"
      },
      {
        href: "/settings/typefacturation",
        title: "Type de Facturation",
        permission: "settings_view"
      },
      {
        href: "/settings/tax",
        title: "Taux de TVA",
        permission: "settings_view"
      },
      {
        href: "/settings/operation",
        title: "Opérations",
        permission: "settings_view"
      },
      {
        href: "/settings/echeance",
        title: "Echéances",
        permission: "settings_view"
      },
      {
        href: "/settings/devise",
        title: "Devises",
        permission: "settings_view"
      },
      {
        href: "/settings/gamme",
        title: "Gammes",
        permission: "settings_view"
      },
      {
        href: "/settings/conditionnement/unit",
        title: "Unité Conditionnement",
        permission: "settings_view"
      },
      {
        href: "/settings/conditionnement/type",
        title: "Type Conditionnement",
        permission: "settings_view"
      },
      {
        href: "/settings/family/matiere",
        title: "Famille de Matière",
        permission: "settings_view"
      },
      {
        href: "/settings/family/article",
        title: "Famille d'Article",
        permission: "settings_view"
      },
      {
        href: "/settings/activity",
        title: "Activité",
        permission: "settings_view"
      },
      {
        href: "/settings/printer",
        title: "Imprimante",
        permission: "settings_view"
      },
    ],
  },
  {
    href: "/gestion/quadratus",
    icon: FileUploadIcon,
    title: "Export Quadratus",
    permission: "quadratus_view"
  },
  {
    href: "/gestion/previsionnel",
    icon: ShowChartIcon,
    title: "Previsionnel",
    permission: "previsionnel_view"
  },
  {
    href: "/export",
    icon: ShowChartIcon,
    title: "Exports",
    children: [
      {
        href: "/export/Devis",
        title: "Devis",
        permission: "export_view",
      },
      {
        href: "/export/Commande",
        title: "Commande",
        permission: "export_view",
      },
      {
        href: "/export/Facture",
        title: "Facture",
        permission: "export_view",
      },
      {
        href: "/export/Achat",
        title: "Achat",
        permission: "export_view",
      },
      {
        href: "/export/Stock",
        title: "Stock",
        permission: "export_view",
      },
      {
        href: "/export/statscommandes",
        title: "Stats Commandes",
        permission: "export_view",
      },
    ],
  },

];


const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
